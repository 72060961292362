document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  // Add a click event on each of them
  $navbarBurgers.forEach((el) => {
    el.addEventListener('click', () => {
      // Get the target from the "data-target" attribute
      const target = el.dataset.target
      const $target = document.getElementById(target)

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      el.classList.toggle('is-active')
      $target.classList.toggle('is-active')
    })
  })

  const $planForms = document.getElementsByClassName('grow-plan-form')

  for (let i = 0; i < $planForms.length; i++) {
    const el = $planForms[i]
    const checkbox = el.querySelector('input[type="checkbox"]')

    checkbox.addEventListener('change', () => {
      const form = el
      fetch(form.action, {
        method: form.method,
        body: new URLSearchParams(new FormData(form)),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong')
          }
        })
        .then((data) => {
          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }
  const $pickForTodayPlanForms = document.getElementsByClassName('grow-plan-form-pick-for-today')

  for (let i = 0; i < $pickForTodayPlanForms.length; i++) {
    const el = $pickForTodayPlanForms[i]
    const button = el.querySelector('button')

    button.addEventListener('click', (e) => {
      e.preventDefault()
      button.classList.add('is-loading')
      const form = el
      fetch(form.action, {
        method: form.method,
        body: new URLSearchParams(new FormData(form)),
      })
        .then((response) => {
          button.classList.remove('is-loading')

          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong')
          }
        })
        .then((data) => {
          button.setAttribute('disabled', 'disabled')
          button.classList.remove('is-outlined')
          button.textContent = 'Picked for today'

          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  const $todayGrowPlanButtons = document.getElementsByClassName('grow-plan-form-complete')

  for (let i = 0; i < $todayGrowPlanButtons.length; i++) {
    const button = $todayGrowPlanButtons[i]
    const el = button.closest('form')

    button.addEventListener('click', (e) => {
      e.preventDefault()
      button.classList.add('is-loading')
      const form = el
      fetch(form.action, {
        method: form.method,
        body: new URLSearchParams(new FormData(form)),
      })
        .then((response) => {
          button.classList.remove('is-loading')

          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong')
          }
        })
        .then((data) => {
          button.setAttribute('disabled', 'disabled')
          button.classList.remove('is-outlined')
          button.textContent = 'Completed'

          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  const notifications = document.getElementsByClassName('global-notification')

  for (let i = 0; i < notifications.length; i++) {
    const el = notifications[i]
    setTimeout(() => {
      el.closest('.global-notifications').classList.add('hidden')
      el.remove()
    }, 5000)
    el.getElementsByClassName('delete')[0].addEventListener('click', () => {
      el.closest('.global-notifications').classList.add('hidden')
      el.classList.add('hidden')
    })
  }

  const userCompetencyEvaluationForms = document.getElementsByClassName(
    'user-competency-evaluation-form'
  )

  for (let i = 0; i < userCompetencyEvaluationForms.length; i++) {
    const el = userCompetencyEvaluationForms[i]
    const select = el.querySelector('select')
    const notes = el.querySelector('textarea')

    select.addEventListener('change', () => {
      const form = el
      const tableCell = select.closest('td')

      tableCell.classList.remove(
        'evaluation-inexperienced',
        'evaluation-expert',
        'evaluation-intermediate',
        'evaluation-unevaluated',
        'evaluation-novice'
      )

      tableCell.classList.add(`evaluation-${select.value}`)

      fetch(form.action, {
        method: form.method,
        body: new URLSearchParams(new FormData(form)),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong')
          }
        })
        .then((data) => {
          const button = form.querySelector('.generate-growth-plan-button')
          button.setAttribute('data-id', data.data.id)

          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    })
    notes.addEventListener('change', () => {
      const form = el
      fetch(form.action, {
        method: form.method,
        body: new URLSearchParams(new FormData(form)),
      })
        .then((response) => {
          if (response.ok) {
            return response.json()
          } else {
            throw new Error('Something went wrong')
          }
        })
        .then((data) => {
          const button = form.querySelector('.generate-growth-plan-button')
          button.setAttribute('data-id', data.data.id)
          console.log(data)
        })
        .catch((error) => {
          console.error(error)
        })
    })
  }

  const userAreaSelect = document.querySelector('[data-container="team-area-select"]')
  const userLevelSelect = document.querySelector('[data-container="team-level-select"]')

  if (userAreaSelect && userLevelSelect) {
    const levelOptions = userLevelSelect.querySelectorAll('option')
    const userAreaValue = userAreaSelect.value || userAreaSelect.options[0].value

    levelOptions.forEach((option) => {
      option.setAttribute('hidden', 'hidden')
      if (option.dataset.area === userAreaValue) {
        option.removeAttribute('hidden')
      }
    })

    userAreaSelect.addEventListener('change', () => {
      const levelOptions = userLevelSelect.querySelectorAll('option')
      const userAreaValue = userAreaSelect.value || userAreaSelect.options[0].value

      let setSelectedLevel = false
      levelOptions.forEach((option) => {
        option.setAttribute('hidden', 'hidden')
        if (option.dataset.area === userAreaValue) {
          option.removeAttribute('hidden')
          if (!setSelectedLevel) {
            userLevelSelect.value = option.value
            setSelectedLevel = true
          }
        }
      })
    })
  }
})

console.log('Hello World')
window.mmd = function mmd(src) {
  var rx_lt = /</g
  var rx_gt = />/g
  var rx_space = /\t|\r|\uf8ff/g
  var rx_escape = /\\([\\\|`*_{}\[\]()#+\-~])/g
  var rx_hr = /^([*\-=_] *){3,}$/gm
  var rx_blockquote = /\n *&gt; *([^]*?)(?=(\n|$){2})/g
  var rx_list = /\n( *)(?:[*\-+]|((\d+)|([a-z])|[A-Z])[.)]) +([^]*?)(?=(\n|$){2})/g
  var rx_listjoin = /<\/(ol|ul)>\n\n<\1>/g
  var rx_highlight =
    /(^|[^A-Za-z\d\\])(([*_])|(~)|(\^)|(--)|(\+\+)|`)(\2?)([^<]*?)\2\8(?!\2)(?=\W|_|$)/g
  var rx_code = /\n((```|~~~).*\n?([^]*?)\n?\2|((    .*?\n)+))/g
  var rx_link = /((!?)\[(.*?)\]\((.*?)( ".*")?\)|\\([\\`*_{}\[\]()#+\-.!~]))/g
  var rx_table = /\n(( *\|.*?\| *\n)+)/g
  var rx_thead = /^.*\n( *\|( *\:?-+\:?-+\:? *\|)* *\n|)/
  var rx_row = /.*\n/g
  var rx_cell = /\||(.*?[^\\])\|/g
  var rx_heading = /(?=^|>|\n)([>\s]*?)(#{1,6}) (.*?)( #*)? *(?=\n|$)/g
  var rx_para = /(?=^|>|\n)\s*\n+([^<]+?)\n+\s*(?=\n|<|$)/g
  var rx_stash = /-\d+\uf8ff/g
  function replace(rex, fn) {
    src = src.replace(rex, fn)
  }
  function element(tag, content) {
    return '<' + tag + '>' + content + '</' + tag + '>'
  }
  function blockquote(src) {
    return src.replace(rx_blockquote, function (all, content) {
      return element('blockquote', blockquote(highlight(content.replace(/^ *&gt; */gm, ''))))
    })
  }
  function list(src) {
    return src.replace(rx_list, function (all, ind, ol, num, low, content) {
      var entry = element(
        'li',
        highlight(
          content
            .split(RegExp('\n ?' + ind + '(?:(?:\\d+|[a-zA-Z])[.)]|[*\\-+]) +', 'g'))
            .map(list)
            .join('</li><li>')
        )
      )
      return (
        '\n' +
        (ol
          ? '<ol start="' +
            (num
              ? ol + '">'
              : parseInt(ol, 36) -
                9 +
                '" style="list-style-type:' +
                (low ? 'low' : 'upp') +
                'er-alpha">') +
            entry +
            '</ol>'
          : element('ul', entry))
      )
    })
  }
  function highlight(src) {
    return src.replace(rx_highlight, function (all, _, p1, emp, sub, sup, small, big, p2, content) {
      return (
        _ +
        element(
          emp
            ? p2
              ? 'strong'
              : 'em'
            : sub
              ? p2
                ? 's'
                : 'sub'
              : sup
                ? 'sup'
                : small
                  ? 'small'
                  : big
                    ? 'big'
                    : 'code',
          highlight(content)
        )
      )
    })
  }
  function unesc(str) {
    return str.replace(rx_escape, '$1')
  }
  var stash = []
  var si = 0
  src = '\n' + src + '\n'
  replace(rx_lt, '&lt;')
  replace(rx_gt, '&gt;')
  replace(rx_space, '  ')
  // blockquote
  src = blockquote(src)
  // horizontal rule
  replace(rx_hr, '<hr/>')
  // list
  src = list(src)
  replace(rx_listjoin, '')
  // code
  replace(rx_code, function (all, p1, p2, p3, p4) {
    stash[--si] = element('pre', element('code', p3 || p4.replace(/^    /gm, '')))
    return si + ''
  })
  // link or image
  replace(rx_link, function (all, p1, p2, p3, p4, p5, p6) {
    stash[--si] = p4
      ? p2
        ? '<img src="' + p4 + '" alt="' + p3 + '"/>'
        : '<a href="' + p4 + '">' + unesc(highlight(p3)) + '</a>'
      : p6
    return si + ''
  })
  // table
  replace(rx_table, function (all, table) {
    var sep = table.match(rx_thead)[1]
    return (
      '\n' +
      element(
        'table',
        table.replace(rx_row, function (row, ri) {
          return row == sep
            ? ''
            : element(
                'tr',
                row.replace(rx_cell, function (all, cell, ci) {
                  return ci ? element(sep && !ri ? 'th' : 'td', unesc(highlight(cell || ''))) : ''
                })
              )
        })
      )
    )
  })
  // heading
  replace(rx_heading, function (all, _, p1, p2) {
    return _ + element('h' + p1.length, unesc(highlight(p2)))
  })
  // paragraph
  replace(rx_para, function (all, content) {
    return element('p', unesc(highlight(content)))
  })
  // stash
  replace(rx_stash, function (all) {
    return stash[parseInt(all)]
  })
  return src.trim()
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope)
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error)
    })
}
